<template>
  <l-map id="map" :zoom="4" :center="[42, -84]">
    <l-tile-layer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      :attribution="$root.mapTileAttribution"
    >
    </l-tile-layer>
    <l-marker
      :lat-lng="[fleet.latitude, fleet.longitude]"
      :icon="truckIcon"
      v-for="fleet in fleetWithValidLatAndLng"
      :key="fleet.id"
    >
    </l-marker>
  </l-map>
</template>

<script>
import L from "leaflet";

export default {
  name: "DashboardMap",
  props: ["fleets"],
  data: () => ({
    truckIcon: L.icon({
      iconUrl: require("@/assets/images/truck.png"),
    }),
  }),
  computed: {
    fleetWithValidLatAndLng() {
      return this.fleets.filter((fleet) => fleet.latitude && fleet.longitude);
    },
  },
};
</script>

<style>
#map {
  height: 400px;
  width: 100%;
}
</style>
